@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  flex-grow: 1;

  @include devices(sm-up) {
    margin-bottom: 20px;
  }

  @include devices(md-up) {
    margin-right: spacing(4);
    margin-bottom: 0;
  }
}

.inputWrapper {
  @include devices(md-up) {
    max-width: 500px;
  }
}

.chips {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chipWrapper {
  display: inline-block;
}

.chip {
  border-radius: 5px;
  margin-right: spacing(1);
  margin-top: spacing(1);
}

.chipLabel {
  font-weight: var(--font-weight-bold);
}

.deleteIconOutlinedColorPrimary {
  width: 14px;
  color: var(--primary-main);
}
