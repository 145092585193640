@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.pageConfig {
  .filterListWrapperLimited {
    margin-left: 0;
    margin-right: 0;

    @include devices(md-up) {
      background-color: var(--common-white);
    }
  }
}

.rootList {
  flex-grow: 1;
}

.filterListWrapperLimited {
  padding: spacing(1.5, 3);
  margin-left: spacing(-3);
  margin-right: spacing(-3);
  background-color: var(--contextual-colors-bg-primary-accent);

  @include devices(md-up) {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
  }
}

/* Filter List Wrapper Full */
.filterListWrapperFull {
  /* Breakpoint: md */
  @include devices(md-up) {
    width: 100%;
    margin-top: spacing(8);
  }
}

.filterListWrapperInPortal {
  @include devices(md-up) {
    margin-top: 42px;
  }
}

.listActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: spacing(2);
}

.sortingFilter {
  max-width: 290px;
  width: 100%;
}

.expertListHeadline {
  font-size: rem(16);
  line-height: rem(26);
  margin-bottom: spacing(1.25);

  @include devices(md-up) {
    display: none;
  }
}
