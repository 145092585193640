@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.suggestionsDropdownClass {
  overflow-y: auto;
  z-index: 2000;
  width: 100%;
  position: absolute;
  top: calc(100% + 4px);
  border-top: 1px solid var(--grey-300);
  background-color: var(--common-white);
  padding: spacing(1, 0);
  max-height: 360px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
}

.container {
  position: relative;
}

.searchForm {
  position: relative;
}

.submitButton {
  top: 1px;
  right: 1px;
  position: absolute;
  border-radius: 0;
  color: var(--common-white);
  background-color: var(--primary-main);

  &:hover {
    background-color: var(--primary-main);
  }

  @include devices(md-up) {
    color: var(--primary-main);
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}

.rootInput {
  padding: 0 50px 0 0;
}
