@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  position: relative;
  padding: 0;
  width: 100%;
}

:global(.MuiAutocomplete-inputRoot) {
  .input.input {
    min-width: 0;
  }
}

.input {
  border-radius: 18px;
  padding: 6px 0 7px 34px;
  height: 21px;
  box-sizing: content-box;

  &:focus {
    border-color: var(--primary-main);
  }

  @include devices(md-up) {
    border: 1px solid var(--text-primary);
  }

  &::placeholder {
    font-size: rem(15);
  }
}

.inputExtended {
  padding-right: 34px;
  border: 1px solid var(--text-primary);
}

.searchButton {
  padding: spacing(1);

  span {
    @include devices(md-up) {
      margin-left: -1px;
    }
  }
}

.closeButton {
  width: spacing(4.5);
  right: -10px;
  padding: spacing(1.5);
}

.adornment {
  position: absolute;
  top: 0;
  bottom: 0;
  max-height: none;
  height: auto;
  margin: 0;
  color: inherit;
}

.adornmentStart {
  left: 0;
  z-index: 3;
}

.adornmentEnd {
  padding: spacing(1.25);
  right: 0;
}
