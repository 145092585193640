@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.suggestionsDropdownClass {
  z-index: 2000;
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 64px;
  left: 0;
  border-top: 1px solid var(--grey-300);

  :global(.MuiAutocomplete-paper) {
    height: 100%;
    border-radius: 0;

    :global(.MuiAutocomplete-listbox) {
      height: 100%;
      max-height: 100%;
    }
  }
}
