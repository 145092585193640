@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.rootFull {
  @include devices(md-up) {
    position: relative;
  }
}

.rootLimited {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  @include devices(md-up) {
    flex-direction: row;
    justify-content: center;
    border-radius: 4px;
  }
}

.rootRounded {
  @include devices(md-up) {
    border-radius: 40px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }
}

.headerFull {
  display: flex;
  align-items: center;
  padding: spacing(2);

  @include devices(md-up) {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
  }
}

.bodyLimited {
  @include devices(md-up) {
    display: flex;
    flex-grow: 1;
  }
}

.footerFull {
  padding: spacing(2);
  background-color: var(--background-default);
  border-top: 1px solid var(--contextual-colors-ui-secondary);

  @include devices(md-up) {
    padding: 0;
    border-top: none;
    background-color: transparent;
  }
}

.bodyFull {
  background-color: var(--background-default);
  padding-top: spacing(1);
  padding-bottom: spacing(4);

  @include devices(md-up) {
    background-color: transparent;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.pageConfig {
  max-width: 940px;

  @include devices(md-up) {
    transform: translateY(-40px);
  }
}
