@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  @include devices(md-up) {
    background-color: transparent;
  }
}

.rootBody {
  padding: spacing(3.5, 1);

  & *:last-child {
    margin-bottom: 0;
  }

  @include devices(md-up) {
    padding: 0;
  }
}

.title {
  font-size: rem(19);
  margin-top: 0;
  margin-bottom: spacing(3);

  @include devices(md-up) {
    font-size: rem(24);
  }
}

.text {
  font-size: rem(14);
  margin-top: 0;
  margin-bottom: spacing(3);

  @include devices(md-up) {
    font-size: rem(17);
  }
}

.recommendedList {
  margin-top: spacing(4);
  padding: spacing(2.5);
  background-color: var(--contextual-colors-bg-primary-accent);
  border-radius: 0;
  margin-left: spacing(-2);
  margin-right: spacing(-2);

  @include devices(md-up) {
    border-radius: 8px;
    margin-top: spacing(9);
    margin-left: 0;
    margin-right: 0;
  }
}

.recommendedListTitle {
  margin-bottom: spacing(1.5);
  font-size: rem(20);

  @include devices(md-up) {
    font-size: rem(32);
  }
}
