@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.item {
  padding: spacing(1, 2);
}

.avatarRoot {
  background-color: var(--contextual-colors-button-disabled);
  width: 34px;
  height: 40px;
  border-radius: 4px;
  margin-right: 0;

  .statusIndicator {
    min-width: 10px;
    min-height: 10px;
    border-width: 1px;
    margin-left: -6px;
    margin-bottom: -7px;
  }
}

.listItemText {
  color: var(--text-primary);

  @include devices(md-down) {
    font-size: rem(18);
  }
}

.listItemAvatarRoot {
  position: relative;
  min-width: 0;
  margin-right: spacing(1.5);
}
