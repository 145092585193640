@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.image {
  margin: 1.25em 0;
  width: 100%;
}

.link {
  color: var(--primary-main);
  border-bottom: 1px dashed var(--primary-main);

  &:hover {
    color: var(--primary-dark);
    border-bottom: 1px dashed var(--primary-dark);
  }
}

.sameFontSize {
  font-size: 1.05rem;

  @include devices(sm-up) {
    font-size: 1.2rem;
  }
}
